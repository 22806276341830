import React from 'react';
import { Typography, Grid, Stack, Divider, Container } from '@mui/material';
import navadhiti from '../../assets/navadhiti_Vd.mp4';
import video from '../../assets/nd_video.mp4';
import { makeStyles } from '@mui/styles';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles({
    video: {
        width: '100%',
        marginTop: '-2rem',
        
        '@media (min-width: 1500px) and (max-width:1900px)': {
            width: '67%',
            height: '60%',
            marginLeft: '10rem'
        },
        '@media (min-width: 1901px) and (max-width:2560px)': {
            width: '67%',
            height: '60%',
            marginLeft: '20rem'
        }
    },

    banner_stack: {
        marginTop: '1.5rem !important',
        justifyContent: 'flex-end',
        '@media (min-width: 280px) and (max-width:315px)': {
            marginTop: '0.5rem !important'
        },
        '@media (min-width: 320px) and (max-width:375px)': {
            marginTop: '0.5rem !important'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            marginTop: '0.5rem !important'
        },
        '@media (min-width: 425px) and (max-width:768px)': {
            marginTop: '0.5rem !important'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            marginTop: '0.5rem !important',
            marginLeft: '2.5rem'
        },
        '@media (min-width: 1340px) and (max-width:1440px)': {
            marginTop: '5rem !important'
        }
    }
});

const BannerData = () => {
    const classes = useStyles();
    return (
        <>
            <Grid sx={{ display: { xs: 'none', md: 'flex', sm: 'flex', lg: 'flex' ,backgroundColor:'#FEFCFE'} } }>
                <video
                    position="relative"
                    autoPlay
                    muted
                    loop
                    loading="eager"
                    className={classes.video}
                >
                    <source src={navadhiti} type="video/mp4" />
                </video>
            </Grid>

            <Grid sx={{ display: { xs: 'flex', md: 'none', sm: 'none', lg: 'none' } }}>
                <video
                    width="100%"
                    height="100%"
                    position="relative"
                    autoPlay
                    muted
                    playsInline
                    loop
                    className={classes.responsivevideo}
                    loading="eager"
                >
                    <source src={video} type="video/mp4" />
                </video>
            </Grid>
            <Container
                id="back-to-top-anchor"
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',

                    zIndex: '2'
                }}
            >
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                    direction={{ xs: 'column', md: 'row', sm: 'row' }}
                >
                    <Grid item xs={6} md={6} sm={6} lg={5} xl={6} classes="banner_grid_datas">
                        <Typography variant="h5">
                            Catalysing Impact
                            <br /> Via{' '}
                            <span style={{ color: '#BECE20', letterSpacing: '2.2px' }}>
                                <b>Technology Solutions</b>
                            </span>
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={5.8} xl={6} classes="banner_grid_data"> */}
                    <Grid item xs={6} sm={6} md={6} lg={5.8} xl={6} classes="banner_grid_data">
                        <StaticImage
                            src="../../images/Banner.png"
                            width={1950}
                            height={1590}
                            float="right"
                            quality={90}
                            loading="eager"
                            right="0"
                            transformOptions={{ fit: 'cover', cropFocus: 'attention' }}
                            className={classes.image}
                        />
                    </Grid>
                </Grid>
                <Stack
                    direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                    spacing={{ xs: 1, sm: 1, md: 1, lg: 3 }}
                    className={classes.banner_stack}
                >
                    <Typography variant="bodyText4">Social Impact</Typography>
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="banner_divider"
                        style={{
                            marginTop: '8px',
                            '@media (max-width: 1024px)': {
                                marginTop: "16px !important"
                            },
                        }}
                    />
                    <Typography variant="bodyText4">Building Credibility</Typography>
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="banner_divider"
                        style={{
                            marginTop: '8px',
                            '@media (max-width: 1024px)': {
                                marginTop: "16px !important"
                            },
                        }}
                    />

                    <Typography variant="bodyText4">Authentic Experiences</Typography>
                    <Divider
                        orientation="vertical"
                        flexItem
                        variant="banner_divider"
                        style={{
                            marginTop: '8px',
                            '@media (max-width: 1024px)': {
                                marginTop: "16px"
                            },
                        }}
                    />
                    <Typography variant="bodyText4">Foster Relationships</Typography>
                </Stack>
            </Container>
            <div id="our-capabilities"></div>
        </>
    );
};
export default BannerData;
